document.addEventListener('DOMContentLoaded', function () {
	var splide = new Splide('.splide', {
		type: 'fade',
		perPage: 1,
		autoplay: false,
		interval: 5000,
		pagination: false,
		arrows: true,
		breakpoints: {
			1024: {
				arrows: false,
				pagination: false,
			},
			992: {
				arrows: false,
				pagination: true,
			},
		}
	}).mount();

	var youtubeVideos = document.querySelectorAll('.youtube-video iframe');

	splide.on('moved', function (newIndex) {
		for (var i = 0; i < youtubeVideos.length; i++) {
			if (i !== newIndex) {
				var videoSrc = youtubeVideos[i].src;
				youtubeVideos[i].src = ''; // Pausa el video
				youtubeVideos[i].src = videoSrc; // Restablece el video
			}
		}
	});
});

document.addEventListener("DOMContentLoaded", function () {
	window.addEventListener('scroll', function () {
		if (window.scrollY > 30) {
			const newLocal = 'legend';
			document.getElementById(newLocal).classList.add('fixed-top');
			// add padding top to show content behind navbar
			//navbar_height = document.querySelector('.navbar').offsetHeight;
		} else {
			document.getElementById('legend').classList.remove('fixed-top');
			// remove padding top from body
		}
	});
});

$(() => {

	$(".js-modal-btn").modalVideo();

	AOS.init({
		disable: 'mobile',
		once: true,
	});

	$('.counter').counterUp({
		delay: 10,
		time: 800
	});

	$(".nav-link").click(function (event) {
		event.preventDefault();
		var target = $(this).attr('href');

		$('html, body').animate({
			scrollTop: $(target).offset().top - 100
		}, 0);
	});



	function carouselProy() {

		var slider = $('#carousel-projects');




		/*slider.slick({
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: false,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						arrows: false
					}
				},
				{
					breakpoint: 992,
					settings: {
						dots: true
					}
				}
			]
		});*/


		$('#carousel-propiedades').slick({
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			dots: false,
			autoplay: true,
			autoplaySpeed: 2500,
			responsive: [
				{
					breakpoint: 1220,
					settings: {
						slidesToShow: 2,
						arrows: false
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
						arrows: false
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						arrows: false,
						dots: true
					}
				}
			],
		});

		$('#carousel-agentes').slick({
			infinite: true,
			slidesToShow: 3,
			slidesToScroll: 1,
			autoplay: true,
			arrows: true,
			dots: false,
			autoplaySpeed: 2500,
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						arrows: false,
					}
				}
			],
		});

	};

	carouselProy();


	function validateForm() {
		// Accept only figure from 0 to 9 and + ( ) in the phone field
		$(".js-form input[name='phone'").keyup(function() {
			$("input[name='phone'").val(this.value.match(/[0-9 + ( )]*/));
		});

		$("#formVender").validate({
			rules: {
				firstname: 'required',
				lastname: 'required',
				email: { required: true, email: true },
				phone: { required: true },
				distrito: 'required',
			},
			messages: {
				firstname: "Campo obligatorio",
				lastname: "Campo obligatorio",
				email: {
					required: "Campo obligatorio",
					email: "Ingresar una dirección de correo válida",
				},
				phone: "Campo obligatorio",
				distrito: "Campo obligatorio",
			},
			submitHandler: function (form) {
				$.ajax({
					url: "/action.php",
					dataType: "json",
					method: "post",
					data: $(form).serialize(),
					success: function (data) {
						$('button[type="submit"]').prop('disabled', false);

						var ResultadoResp = data.response;

						console.log(ResultadoResp);

						if (ResultadoResp == '0') {
						}
						if (ResultadoResp == '1') {
							window.location.href = "gracias.php";
						}
					},
					beforeSend: function () {
						$('button[type="submit"]').prop('disabled', true);
					}
				});
			}
		});
	}
		validateForm();

	});
